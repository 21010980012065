@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ionic/angular/css/display.css";
ion-app.mobile .aio-mobile-hide,
ion-app.web-browser .aio-web-browser-hide,
.aioHidden {
  display: none !important;
}

.aio-validation-error {
  color: var(--ion-color-danger);
  --padding-top: 5px;
  --padding-bottom: 5px;
  padding: var(--padding-top, 5px) var(--padding-end, 15px) var(--padding-bottom, 5px) var(--padding-start, 15px);
  text-align: start;
}

ion-content.aio-vertical-align-center::part(scroll),
ion-content.aio-vertical-align-bottom::part(scroll) {
  display: flex;
  padding-top: 0px;
}

ion-content.aio-vertical-align-center::part(scroll) {
  align-items: center;
  padding-bottom: 10px;
}

ion-content.aio-vertical-align-bottom::part(scroll) {
  align-items: flex-end;
  padding-bottom: 0px;
}

.aio-vertical-align-wrapper {
  flex: auto;
}

ion-item.item-label-stacked.item-input > ion-icon[slot=start],
ion-item.item-label-stacked.item-input > ion-icon[slot=end],
ion-item.item-label-floating.item-input > ion-icon[slot=start],
ion-item.item-label-floating.item-input > ion-icon[slot=end],
ion-item.item-label-floating::part(detail-icon),
ion-item.item-label-stacked::part(detail-icon) {
  margin-top: auto;
}

ion-item.item-label-stacked.item-textarea > ion-icon[slot=start],
ion-item.item-label-stacked.item-textarea > ion-icon[slot=end],
ion-item.item-label-floating.item-textarea > ion-icon[slot=start],
ion-item.item-label-floating.item-textarea > ion-icon[slot=end] {
  margin-top: 10px;
}

ion-item.item-label-floating::part(detail-icon),
ion-item.item-label-stacked::part(detail-icon) {
  margin-bottom: 10px;
}

/* Fix Ionic 5 issues */
ion-item ion-reorder:not([slot]) {
  z-index: 100;
}

/** ngx-datatable fix pagination **/
@media only screen and (max-width: 500px) {
  .ngx-datatable.material .datatable-footer .page-count {
    white-space: nowrap;
  }
  .ngx-datatable.material .datatable-footer .pager {
    white-space: nowrap;
  }
}
ion-menu::part(container) {
  box-sizing: border-box;
}

ion-toolbar {
  width: auto;
}

ion-toolbar.ios ion-title.title-large {
  text-align: center;
}

/* [ETST-42825 Ionic Input Margin Bug] Temporary fix for ion-input width */
:not(ion-item) > ion-input.sc-ion-input-ios-h,
:not(ion-item) > ion-input.sc-ion-input-md-h {
  width: auto;
}

.aio-component-divider {
  width: 100%;
}

.aio-component-divider::before {
  display: block;
  content: "";
}

.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}

.action-sheet-wrapper.sc-ion-action-sheet-ios {
  top: 0;
  height: auto;
}

aio-datetime {
  display: flex;
  position: static;
}

aio-datetime.no-item {
  position: relative;
}

.aio-datetime-modal ion-datetime {
  width: 100%;
  height: 100%;
}

[ng-reflect-router-link] {
  cursor: pointer;
}

/*
* Appery.io component.
*/
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

.img-rounded {
  border-radius: 6px;
}

ion-img::part(image) {
  box-sizing: border-box;
}

@media (min-width: 768px) {
  ion-modal {
    --border-radius: 8px;
  }
}