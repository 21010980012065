body.Default {
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56,128,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61,194,255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  --ion-background-color: #f2f2ff;
  --ion-background-color-rgb: 242,242,255;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0,0,0;
  --ion-color-step-50: #e6e6f2;
  --ion-color-step-100: #dadae6;
  --ion-color-step-150: #ceced9;
  --ion-color-step-200: #c2c2cc;
  --ion-color-step-250: #b6b6bf;
  --ion-color-step-300: #a9a9b3;
  --ion-color-step-350: #9d9da6;
  --ion-color-step-400: #919199;
  --ion-color-step-450: #85858c;
  --ion-color-step-500: #797980;
  --ion-color-step-550: #6d6d73;
  --ion-color-step-600: #616166;
  --ion-color-step-650: #555559;
  --ion-color-step-700: #49494d;
  --ion-color-step-750: #3d3d40;
  --ion-color-step-800: #303033;
  --ion-color-step-850: #242426;
  --ion-color-step-900: #18181a;
  --ion-color-step-950: #0c0c0d;
  --ion-color-primary: #0a3d94;
  --ion-color-primary-rgb: 10,61,148;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #093682;
  --ion-color-primary-tint: #23509f;
  --ion-color-secondary: #3c46b4;
  --ion-color-secondary-rgb: 60,70,180;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #353e9e;
  --ion-color-secondary-tint: #5059bc;
  --ion-color-tertiary: #373fa0;
  --ion-color-tertiary-rgb: 55,63,160;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #30378d;
  --ion-color-tertiary-tint: #4b52aa;
  --ion-color-success: #729d83;
  --ion-color-success-rgb: 114,157,131;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #648a73;
  --ion-color-success-tint: #80a78f;
  --ion-color-warning: #b68e0c;
  --ion-color-warning-rgb: 182,142,12;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #a07d0b;
  --ion-color-warning-tint: #bd9924;
  --ion-color-danger: #ee3f56;
  --ion-color-danger-rgb: 238,63,86;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d1374c;
  --ion-color-danger-tint: #f05267;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 176,181,196;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #9b9fac;
  --ion-color-light-tint: #b8bcca;
}